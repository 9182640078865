import * as React from "react";
import Link from "next/link";

import { FlexRow } from "../../../components/Container";
import { Typography } from "@papercup-ai/crane";
import LogoSVG from "../../../assets/svg/logo.svg";
import LogoFull from "../../../assets/svg/logoFull.svg";

export const PapercupLogo = ({ compact }: { compact?: boolean }) => (
  <Link
    passHref
    href={{
      pathname: "/schedule"
    }}
  >
    <a>
      <FlexRow
        flex={{
          fai: "items-center"
        }}
        dimensions={{
          pad: "pa4"
        }}
      >
        {compact ? (
          <LogoSVG width={"32px"} />
        ) : (
          <>
            <LogoFull width={"245px"} height="80px" className="mr2" />
          </>
        )}
      </FlexRow>
    </a>
  </Link>
);
