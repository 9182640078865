import { FlexColumn, FlexRow, Icon, TypographyV2 } from "@papercup-ai/crane";
import * as React from "react";
import { PapercupLogo } from "../SelfService/PageWithNavigation/PapercupLogo";
import ImpactSvg from "../../assets/svg/impact.svg";
import EthicSvg from "../../assets/svg/ethic.svg";
import DaringSvg from "../../assets/svg/daring.svg";
import InterSvg from "../../assets/svg/inter.svg";
import PapercupySvg from "../../assets/svg/papercupy.svg";
import PeopleChoiceSvg from "../../assets/svg/people.svg";
import PrizeSvg from "../../assets/svg/prize.svg";
import MentorSvg from "../../assets/svg/mentor.svg";
import AwardsSvg from "../../assets/svg/awards.svg";
import { motion } from "framer-motion";
import Confetti from "react-dom-confetti";
import ScratchCard from "react-scratchcard";

const mappingSvg = {
  "Most impactful solution": ImpactSvg,
  "Most ethical value": EthicSvg,
  "Most daring / bleeding edge": DaringSvg,
  "Most interdisciplinary": InterSvg,
  "Most Papercupy": PapercupySvg,
  "People's choice": PeopleChoiceSvg,
  "Grand Prize": PrizeSvg,
  "Best Mentor": MentorSvg
};

const HackathonLogo = () => {
  return (
    <FlexColumn
      background="bg-splash"
      dimensions={{ pad: "pa5" }}
      layout={{ pos: "relative" }}
      flex={{ fai: "items-center", fjc: "justify-center" }}
    >
      <TypographyV2.H3 colour="c-contrast">PAPERCUP</TypographyV2.H3>
      <div
        style={{
          fontFamily: "TrashHand",
          position: "absolute",
          bottom: "-15px",
          fontSize: "42px",
          right: "-2px",
          whiteSpace: "nowrap",
          fontWeight: "bold",
          WebkitTextStroke: "1px rgba(0,0,0,0.2)",
          textShadow: "4px 2px rgb(22, 206, 109)",
          color: "#fff"
        }}
      >
        HACKATHON 2020
      </div>
    </FlexColumn>
  );
};

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

export const AwardsWelcome = () => {
  const [confetti, setConfetti] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => {
      setConfetti(true);
    }, 2000);
  }, []);
  return (
    <FlexColumn
      background="bg-splash"
      dimensions={{ w: "vw-100", h: "vh-100", pad: "pa5" }}
    >
      <FlexColumn
        background="bg-primary"
        dimensions={{ w: "w-100", h: "h-100", pad: "pa5" }}
        flex={{ fai: "items-center", fjc: "justify-center" }}
      >
        <motion.div
          className="container"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          <motion.div className="item" variants={item}>
            <PapercupLogo />
          </motion.div>
          <motion.div className="item flex justify-center" variants={item}>
            <AwardsSvg width="300px" height="310px" />
          </motion.div>
          <motion.div className="item flex justify-center" variants={item}>
            <FlexColumn
              background="bg-splash"
              dimensions={{ pad: "pa5" }}
              layout={{ pos: "relative" }}
              flex={{ fai: "items-center", fjc: "justify-center" }}
            >
              <TypographyV2.H3 colour="c-contrast">PAPERCUP</TypographyV2.H3>
              <div
                style={{
                  fontFamily: "TrashHand",
                  position: "absolute",
                  bottom: "-15px",
                  fontSize: "42px",
                  right: "-2px",
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                  WebkitTextStroke: "1px rgba(0,0,0,0.2)",
                  textShadow: "4px 2px rgb(22, 206, 109)",
                  color: "#fff"
                }}
              >
                HACKATHON 2020
              </div>
              <TypographyV2.H3
                colour="c-contrast"
                style={{
                  position: "absolute",
                  bottom: "-38px",
                  fontSize: "36px",
                  right: " -13px",
                  letterSpacing: "10px",
                  color: "rgb(252, 78, 54)"
                }}
              >
                AWARDS
              </TypographyV2.H3>
            </FlexColumn>
          </motion.div>
        </motion.div>
        <Confetti
          active={confetti}
          config={{
            angle: 90,
            spread: 360,
            startVelocity: 40,
            elementCount: 200
          }}
        />
      </FlexColumn>
    </FlexColumn>
  );
};

const longArray = Array.from(Array(30).keys());
export const Filler = ({ text }: any) => {
  return (
    <FlexColumn
      background="bg-splash"
      dimensions={{ w: "vw-100", h: "vh-100", pad: "pa5" }}
      flex={{ fai: "items-center", fjc: "justify-center" }}
      style={{
        backgroundImage: "url(/static/tile.png)",
        backgroundAttachment: "fixed",
        overflow: "hidden"
      }}
    >
      <motion.div
        className="container"
        variants={container}
        initial="hidden"
        animate="visible"
      >
        {text && (
          <>
            {longArray.map(i => (
              <motion.div className="item" key={i} variants={item}>
                {" "}
                <FlexRow
                  background="bg-primary"
                  flex={{ fai: "items-center", fjc: "justify-center" }}
                  dimensions={{ pad: "pa4" }}
                >
                  <TypographyV2.H2>{text}</TypographyV2.H2>
                </FlexRow>
              </motion.div>
            ))}
          </>
        )}
      </motion.div>
    </FlexColumn>
  );
};

const Signatures = () => {
  return (
    <FlexColumn dimensions={{ m: ["mt6"] }}>
      <TypographyV2.H5 colour="c-secondary" m="mt4" classNameOverride="center">
        awared by
      </TypographyV2.H5>
      <FlexRow flex={{ fai: "items-center", fjc: "justify-center" }}>
        <FlexColumn
          dimensions={{ m: ["mt5", "mh5"] }}
          flex={{ fai: "items-center" }}
          layout={{ pos: "relative" }}
        >
          <TypographyV2.H5
            m=""
            style={{
              fontFamily: "Doniyor",
              fontSize: "26px",
              position: "relative",
              right: "25px",
              bottom: "-3px",
              transform: "rotate(-3deg)"
            }}
          >
            D
          </TypographyV2.H5>
          <TypographyV2.H5
            m=""
            style={{
              fontFamily: "Doniyor",
              fontSize: "26px",
              position: "absolute",
              top: "0px",
              transform: "rotate(-3deg)"
            }}
          >
            oniyor
          </TypographyV2.H5>
          <FlexRow
            border={{ b: "bt", bc: "b--gray" }}
            dimensions={{ pad: ["ph5", "pv3"] }}
          >
            <TypographyV2.H5 m="">Doniyor Ulmasov</TypographyV2.H5>
          </FlexRow>
        </FlexColumn>
        <FlexColumn
          dimensions={{ m: ["mt5", "mh5"] }}
          flex={{ fai: "items-center" }}
          layout={{ pos: "relative" }}
        >
          <TypographyV2.H5
            m=""
            style={{
              fontFamily: "Margarita",
              fontSize: "18px",
              position: "relative",
              bottom: "-3px",
              transform: "rotate(-3deg)"
            }}
          >
            MargaritaL
          </TypographyV2.H5>

          <FlexRow
            border={{ b: "bt", bc: "b--gray" }}
            dimensions={{ pad: ["ph5", "pv3"] }}
          >
            <TypographyV2.H5 m="">Margarita Letitchevski</TypographyV2.H5>
          </FlexRow>
        </FlexColumn>
        <FlexColumn
          dimensions={{ m: ["mt5", "mh5"] }}
          flex={{ fai: "items-center" }}
          layout={{ pos: "relative" }}
        >
          <TypographyV2.H5
            m=""
            style={{
              fontFamily: "Marlene",
              fontSize: "18px",
              position: "relative",
              bottom: "-2px",
              transform: "rotate(-3deg)"
            }}
          >
            MarleneS
          </TypographyV2.H5>

          <FlexRow
            border={{ b: "bt", bc: "b--gray" }}
            dimensions={{ pad: ["ph5", "pv3"] }}
          >
            <TypographyV2.H5 m="">Marlene Staib</TypographyV2.H5>
          </FlexRow>
        </FlexColumn>
        <FlexColumn
          dimensions={{ m: ["mt5", "mh5"] }}
          flex={{ fai: "items-center" }}
          layout={{ pos: "relative" }}
        >
          <TypographyV2.H5
            m=""
            style={{
              fontFamily: "Vivian",
              fontSize: "18px",
              position: "relative",
              bottom: "-2px",
              transform: "rotate(-3deg)"
            }}
          >
            VivianHu
          </TypographyV2.H5>

          <FlexRow
            border={{ b: "bt", bc: "b--gray" }}
            dimensions={{ pad: ["ph5", "pv3"] }}
          >
            <TypographyV2.H5 m="">Vivian Hu</TypographyV2.H5>
          </FlexRow>
        </FlexColumn>
      </FlexRow>
    </FlexColumn>
  );
};

export const Award = ({ category, winner }: any) => {
  const [confetti, setConfetti] = React.useState(false);
  const Svg = mappingSvg[category as keyof typeof mappingSvg];

  const settings = {
    width: 500,
    height: 120,
    image: "/static/tile_background.png",
    finishPercent: 50,
    onComplete: () => setConfetti(true)
  };
  return (
    <FlexColumn
      background="bg-splash"
      dimensions={{ w: "vw-100", h: "vh-100", pad: "pa5" }}
    >
      <FlexColumn
        background="bg-primary"
        dimensions={{ w: "w-100", h: "h-100", pad: "pa5" }}
      >
        <FlexRow flex={{ fjc: "justify-between", fai: "items-center" }}>
          <PapercupLogo />
          <HackathonLogo />
        </FlexRow>
        <FlexColumn flex={{ fai: "items-center" }}>
          <Svg width="200px" height="210px" />
          <TypographyV2.H3 colour="c-brand">{category}</TypographyV2.H3>
          <TypographyV2.H4 colour="c-brand" m="mt3">
            Papercup Hackathon 2020 Winner
          </TypographyV2.H4>
          <TypographyV2.H4 colour="c-brand" m="mt3">
            14th-18th December 2020
          </TypographyV2.H4>
          <TypographyV2.H4 colour="c-secondary" m="mt3">
            London - Dublin - New York - Florence - Warsaw - Paris
          </TypographyV2.H4>
          <TypographyV2.H5 colour="c-secondary" m="mt4">
            has been awarded to
          </TypographyV2.H5>
          <Confetti
            active={confetti}
            config={{
              angle: 90,
              spread: 760,
              startVelocity: 40,
              elementCount: 200
            }}
          />
          <ScratchCard {...settings}>
            <FlexColumn
              background="bg-secondary"
              dimensions={{ pad: "pa3", m: "mt4" }}
              border={{ b: "ba", bc: "b--gray-20", bw: "bw2" }}
              flex={{ fjc: "justify-between", fai: "items-center" }}
            >
              <TypographyV2.H1 colour="c-brand" m="">
                {winner}
              </TypographyV2.H1>
            </FlexColumn>
          </ScratchCard>
        </FlexColumn>

        <Signatures />
      </FlexColumn>
    </FlexColumn>
  );
};

export const ScrollBy100Vh = () => {
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 100,
        bottom: 50,
        right: 50,
        width: "50px",
        height: "50px",
        borderRadius: "100%",
        color: "white",
        fontSize: "38px",
        cursor: "pointer"
      }}
      className="bg-splash flex items-center c-primary justify-center"
      onClick={() => {
        window.scrollBy({
          top: window.innerHeight,
          behavior: "smooth"
        });
      }}
    >
      &#9759;
    </div>
  );
};
