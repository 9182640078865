import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient } from "apollo-client";
import { ApolloLink, concat } from "apollo-link";
import { setContext } from "apollo-link-context";
import { WebSocketLink } from "apollo-link-ws";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { getMainDefinition } from "apollo-utilities";

import { Auth0Context } from "../../context";
import { Auth0Provider } from "../../providers/Auth0Provider";
import { InterfacePage } from "../../@types/data";

const withToken = setContext(() => {});

const ApolloProviderWrapper: React.FunctionComponent = ({ children }) => {
  // Init Apollo only if the user is logged in
  const { isAuthenticated, getTokenSilently } = useContext(Auth0Context);
  const [token, setToken] = useState();

  useEffect(() => {
    const s = async () => {
      if (isAuthenticated) {
        const t = await getTokenSilently();
        setToken(t);
      }
    };
    s();
  }, [isAuthenticated]);

  const [apolloClient, setApolloClient] = useState<ApolloClient<any>>();
  useEffect(() => {
    if (isAuthenticated && token) {
      const httpLink = new HttpLink({
        uri: process.env.BINDER_URI
      });

      const authMiddleware = new ApolloLink((operation, forward) => {
        // To make TypeScript stop complaining
        if (forward !== undefined) {
          operation.setContext({
            headers: {
              authorization: `Bearer-auth0 ${token}`
            }
          });

          return forward(operation);
        }
        return null;
      });

      const authHttpLink = concat(authMiddleware, httpLink);

      // https://www.apollographql.com/docs/react/advanced/subscriptions/#client-setup
      // Create a WebSocket link:
      const wsLink = new WebSocketLink({
        uri: (process.env.BINDER_URI || "").replace(/http/, "ws"),
        options: {
          reconnect: true
        }
      });

      // using the ability to split links, you can send data to each link
      // depending on what kind of operation is being sent
      const link = withToken.split(
        // split based on operation type
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        wsLink,
        authHttpLink
      );

      setApolloClient(
        new ApolloClient({
          link: link,
          cache: new InMemoryCache()
        })
      );
    }
  }, [isAuthenticated, token]);
  return apolloClient ? (
    <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
  ) : (
    <>{children}</>
  );
};

export const WithProvidersAuth0 = <T extends Partial<InterfacePage>>(
  Component: React.JSXElementConstructor<T>
) => (props: T) => {
  if (typeof window === "undefined") {
    return null;
  }

  if (props?.accessLevel === "public") {
    <Auth0Provider
      client_id={process.env.AUTH0_CLIENT_ID || ""}
      domain={process.env.AUTH0_DOMAIN || ""}
      redirect_uri={window.location.origin}
      audience={process.env.AUTH0_AUDIENCE}
      connection={"google-oauth2"}
      access_type={"offline"}
      connection_scope={
        "profile email openid https://www.googleapis.com/auth/youtube.readonly read:current_user update:current_user_metadata"
      }
    >
      <Component {...props} />
    </Auth0Provider>;
  }

  return (
    <Auth0Provider
      client_id={process.env.AUTH0_CLIENT_ID || ""}
      domain={process.env.AUTH0_DOMAIN || ""}
      apiAudience={process.env.AUTH0_API_AUDIENCE || ""}
      redirect_uri={window.location.origin}
      audience={`${process.env.AUTH0_AUDIENCE}`}
      connection={"google-oauth2"}
      access_type={"offline"}
      connection_scope={
        "profile email openid https://www.googleapis.com/auth/youtube.readonly read:current_user update:current_user_metadata"
      }
    >
      <ApolloProviderWrapper>
        <Component {...props} />
      </ApolloProviderWrapper>
    </Auth0Provider>
  );
};
