import * as React from "react";
import { PageBase } from "../src/pages/SelfService/PageBase";
import {
  Award,
  AwardsWelcome,
  Filler,
  ScrollBy100Vh
} from "../src/pages/Awards/Awards";

export default ({ data }: any) => (
  <PageBase accessLevel={"public"} viewport={false}>
    <AwardsWelcome />
    {Object.keys(data).map(key => (
      <>
        <Filler text={key.toUpperCase()} />
        <Award key={key} winner={data[key]} category={key} />
      </>
    ))}
    <ScrollBy100Vh />
  </PageBase>
);

export async function getStaticProps() {
  const res = await fetch(
    "https://papercup-publications.s3-eu-west-1.amazonaws.com/winners.json"
  );
  const data = await res.json();

  if (!data) {
    return {
      notFound: true
    };
  }
  return {
    props: { data } // will be passed to the page component as props
  };
}
