import createAuth0Client from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import { camelCase, mapKeys, omit } from "lodash";
import { useRouter } from "next/router";
import React, { useEffect, useState, useContext } from "react";
import { Auth0User } from "../@types/data";
import { Auth0Context, InterfaceAuth0Context } from "../context";

export const useAuth0Context = () =>
  useContext<InterfaceAuth0Context>(Auth0Context);

// Keep state arg there, incase if we decide to use it.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DEFAULT_REDIRECT_CALLBACK = (state?: any) =>
  window.history.replaceState({}, document.title, window.location.pathname);

const parseProfile = (user: any): Auth0User => {
  const profile = mapKeys(user, (_: any, k: string) =>
    camelCase(k)
  ) as Auth0User;

  return profile;
};

export const Auth0Provider: React.ComponentType<{
  onRedirectCallback?: (state?: any) => void;
  apiAudience?: string;
} & Auth0ClientOptions> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  apiAudience,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<Auth0User | undefined>();
  const [userManagementToken, setUserManagementToken] = useState<
    string | undefined
  >();
  const [auth0Client, setAuth0] = useState<Auth0Client | undefined>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const { push, query, pathname } = useRouter();

  useEffect(() => {
    const initAuth0 = async () => {
      const _auth0client = await createAuth0Client(initOptions);
      setAuth0(_auth0client);
      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await _auth0client.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await _auth0client.isAuthenticated();
      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await _auth0client.getUser();
        setUser(parseProfile(user));
        push({
          pathname,
          query: omit(query, ["code", "state"])
        });
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  // Pages which do not have management URL, don't need to get a token since they are not used to manage the user data
  useEffect(() => {
    if (user && apiAudience) {
      auth0Client
        ?.getTokenSilently({
          connection: "",
          scope: "read:current_user update:current_user_metadata",
          audience: apiAudience
        })
        .then(token => setUserManagementToken(token))
        .catch(() => {
          try {
            auth0Client
              .getTokenWithPopup({
                connection: "",
                scope: "read:current_user update:current_user_metadata",
                audience: apiAudience
              })
              .then(token => setUserManagementToken(token));
          } catch (error) {
            console.error(error);
          }
        });
    }
  }, [user?.sub, apiAudience]);

  useEffect(() => {
    if (userManagementToken) {
      const func = async () => {
        let userData = await (
          await fetch(
            `https://${initOptions.domain}/api/v2/users/${user?.sub}`,
            {
              headers: {
                Authorization: `Bearer ${userManagementToken}`,
                "Content-Type": "application/json"
              }
            }
          )
        ).json();

        const utms = window.localStorage.getItem("PAPERCUP_UTM_PARAMS");
        if (utms) {
          if (!userData.attribution) {
            userData = await (
              await fetch(
                `https://${initOptions.domain}/api/v2/users/${user?.sub}`,
                {
                  method: "PATCH",
                  body: JSON.stringify({
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    user_metadata: {
                      sources: (
                        (userData.user_metadata.sources as any[]) || []
                      ).concat([JSON.parse(utms)]),
                      ...(!userData.user_metadata.attribution && {
                        attribution: JSON.parse(utms)
                      })
                    }
                  }),
                  headers: {
                    Authorization: `Bearer ${userManagementToken}`,
                    "Content-Type": "application/json"
                  }
                }
              )
            ).json();
            window.localStorage.removeItem("PAPERCUP_UTM_PARAMS");
          }
        }

        setUser({
          ...user,
          // eslint-disable-next-line @typescript-eslint/camelcase
          appMetadata: userData.app_metadata,
          // eslint-disable-next-line @typescript-eslint/camelcase
          userMetadata: userData.user_metadata
        } as Auth0User);
      };
      func();
    }
  }, [userManagementToken]);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client?.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client?.getUser();
    setUser(parseProfile(user));
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client?.handleRedirectCallback();
    const user = await auth0Client?.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(parseProfile(user));
    // Once we login, we want to remove code and state from the query parameters. Otherwise reloading will result in an error from auth0

    push({
      pathname,
      query: omit(query, ["code", "state"])
    });
  };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: auth0Client?.getIdTokenClaims.bind(auth0Client),
        loginWithRedirect: auth0Client?.loginWithRedirect.bind(auth0Client),
        getTokenSilently: auth0Client?.getTokenSilently.bind(auth0Client),
        getTokenWithPopup: auth0Client?.getTokenWithPopup.bind(auth0Client),
        logout: (props: LogoutOptions) => {
          auth0Client?.logout({
            returnTo: window.origin,
            ...props
          });
        }
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
