import * as React from "react";
import { useContext, useEffect } from "react";
import { FlexColumn } from "../../components/Container";
import { Auth0Context } from "../../context";
import { WithProvidersAuth0 } from "./withProvidersAuth0";
import { LoadingPage } from "../../components/LoadingPage";
import { useGA } from "../../hooks/analytics/useGA";
import { InterfacePage } from "../../@types/data";
import { getApolloContext } from "@apollo/react-hooks";
import { useAsDefaultTheme } from "../../providers/Theme";
import { useRouter } from "next/router";
import pick from "lodash.pick";

const Page: React.FunctionComponent<InterfacePage> = ({
  accessLevel = "private",
  defaultTheme = "light",
  viewport = true,
  children
}) => {
  useGA();
  useAsDefaultTheme(defaultTheme);
  const { query } = useRouter();
  const { isAuthenticated, loginWithRedirect, loading } = useContext(
    Auth0Context
  );
  const { client } = useContext(getApolloContext());

  useEffect(() => {
    const utms = pick(query, [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content"
    ]);
    if (Object.keys(utms).length > 0) {
      window.localStorage.setItem(
        "PAPERCUP_UTM_PARAMS",
        JSON.stringify({
          // eslint-disable-next-line @typescript-eslint/camelcase
          landing_time: new Date().toISOString(),
          ...utms
        })
      );
    }
  }, []);

  if (typeof window === "undefined" || loading) {
    return <LoadingPage />;
  }

  if (accessLevel === "publicOnly" && isAuthenticated) {
    return <LoadingPage />;
  }
  if (accessLevel === "private" && !isAuthenticated) {
    loginWithRedirect({
      connection: "google-oauth2",
      // eslint-disable-next-line @typescript-eslint/camelcase
      approval_prompt: "force",
      // eslint-disable-next-line @typescript-eslint/camelcase
      connection_scope:
        "profile email openid https://www.googleapis.com/auth/youtube.readonly",
      // eslint-disable-next-line @typescript-eslint/camelcase
      access_type: "offline",
      // eslint-disable-next-line @typescript-eslint/camelcase
      redirect_uri: window.origin
    });
    return <LoadingPage />;
  }

  if (!client && accessLevel === "private") {
    return <LoadingPage />;
  }

  return (
    <FlexColumn
      background={"bg-primary"}
      dimensions={viewport ? { h: "vh-100" } : {}}
      flex={{
        fai: "items-center",
        fjc: "justify-center"
      }}
      style={{ overflow: "hidden" }}
    >
      {children}
    </FlexColumn>
  );
};

export const PageBase = WithProvidersAuth0(Page);
